import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUserAuthenticated } from 'utils/auth/auth';
import { actions as caseActions } from 'redux/api/casesv2Search/casesv2Search';
import { name as searchName, actions as searchActions } from 'redux/api/search/search';
import { actions as userActions } from 'redux/api/user/user';
import { actions as chatHistoryActions } from 'redux/api/chatHistory/chatHistory';
import ANALYTICS from 'utils/analytics/analytics';
import CONFIG from 'config';

const AppInit = ({ pageName }) => {
  const dispatch = useDispatch();
  const { authorizeCaseUser } = caseActions;
  const { profiles } = useSelector((state) => state[searchName]);
  const debounceTimeout = useRef(null);
  const isAuthenticated = useRef(false);

  useEffect(() => {
    const loadApp = async () => {
      if (await isUserAuthenticated()) {
        isAuthenticated.current = true;
        dispatch(userActions.getUserRegionFromApi());
        dispatch(userActions.getIsPilotUser());
        dispatch(userActions.fetchUserProfilePhoto());  
        if(!CONFIG.FEATURE_TOGGLES.SHOW_MATERIALS_GRID_LIST_VIEW) {
          dispatch(userActions.getMaterialsAInonAIAccess());      
        }
        dispatch(authorizeCaseUser());
        dispatch(chatHistoryActions.fetchGenAICustomerId());
        if (profiles.length === 0) {
          dispatch(searchActions.searchUserConfigProfile());
        }
      }
    };

    loadApp();
  }, []);

  //Added this useEffect to track the Page Load event only once
  useEffect(() => {
    if (isAuthenticated.current) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        ANALYTICS.page.initialize(pageName, 'Page', true);
      }, 1500);
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [pageName, isAuthenticated.current]);

  return (<></>);
};

export default AppInit;